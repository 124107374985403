<template>
  <el-container>
    <div class="w-100 shield-cont">
      <el-card class="content box-card min-h-600" v-loading="loading">
        <div>
          <el-tabs v-model="activeName" @tab-click="handleClick">
            <el-tab-pane label="屏蔽信息" name="info">
              <p class="sub fz-14">屏蔽信息只保留15天，逾期将被清除</p>
              <el-row class="empty" v-if="data.length < 1">
                <div>
                  <img
                    src="@/assets/images/common/empty/pbxx_kong.svg"
                    alt="暂无屏蔽信息"
                    width="400"
                    class="img"
                    type="image/svg+xml"
                  />
                  <p><span>暂无屏蔽信息</span></p>
                </div>
              </el-row>
              <div v-if="data.length >= 1">
                <div class="mod-list mar-t-20 shield-info">
                  <ul>
                    <li>
                      <h3>
                        <span class="mod-list-title2">标题</span>
                        <span class="mod-list-title1">发布时间</span>
                        <span class="mod-list-title1">标记噪音时间</span>
                        <span class="tag">标记账户</span>
                        <span class="tag">操作</span>
                      </h3>
                    </li>
                    <li v-for="(item, index) in data" :key="index">
                      <a class="mod-list-title2" @click="showDetail(item)">{{
                        item.title
                      }}</a>
                      <span class="mod-list-title1">{{ item.published }}</span>
                      <span class="mod-list-title1">{{ item.mark_time }}</span>
                      <span class="tag">{{ item.uid_name }}</span>
                      <span class="tag">
                        <a
                          :class="{
                            jy: item.uid.indexOf(user.id) === -1,
                            'return-btn': true
                          }"
                          @click="cancelMark(item, index)"
                          >还原</a
                        >
                      </span>
                    </li>
                  </ul>
                </div>
                <!-- 分页 -->
                <div class="page-bottom mar-t-30">
                  <!-- <el-pagination
                    :hide-on-single-page="singPage"
                    @size-change="handleSizeChange"
                    @current-change="handleCurrentChange"
                    :current-page="currentPage"
                    :page-size="size"
                    :page-sizes="[10, 20, 50]"
                    layout="sizes, prev, pager, next"
                    :total="total"
                  >
                  </el-pagination> -->
                  <el-pagination
                    :hide-on-single-page="singPage"
                    @size-change="handleSizeChange"
                    @current-change="handleCurrentChange"
                    :current-page="currentPage"
                    layout="prev, pager, next"
                    :total="total"
                  >
                  </el-pagination>
                </div>
                <!-- @end分页 -->
              </div>
            </el-tab-pane>
            <el-tab-pane label="屏蔽信源" name="source">
              <p class="sub fz-14">您将不会收到本账户屏蔽的信源发布的信息</p>
              <el-row
                class="empty"
                v-if="sourceData.length < 1"
                v-loading="loading"
              >
                <div>
                  <img
                    src="@/assets/images/common/empty/pbxx_kong.svg"
                    alt="暂无屏蔽信源"
                    width="400"
                    class="img"
                    type="image/svg+xml"
                  />
                  <p><span>暂无屏蔽信源</span></p>
                </div>
              </el-row>
              <div v-else v-loading="loading">
                <div class="mod-list mar-t-20 shield-source">
                  <ul>
                    <li>
                      <h3>
                        <span class="mod-list-title1">名称</span>
                        <span class="mod-list-title2">作者</span>
                        <span class="mod-list-title2">网址</span>
                        <span class="tag">媒体类型</span>
                        <span class="tag">标记时间</span>
                        <span class="tag">标记账户</span>
                        <span class="tag">操作</span>
                      </h3>
                    </li>
                    <li v-for="(row, index) in sourceData" :key="index">
                      <span class="mod-list-title1">{{ row.source_name }}</span>
                      <span class="mod-list-title2">{{
                        row.author ? row.author : '--'
                      }}</span>
                      <span class="mod-list-title2">{{ row.host }}</span>
                      <span class="tag">{{ row.category }}</span>
                      <span class="tag">{{ row.date ? row.date : '--' }}</span>
                      <span class="tag">{{ row.created_name }}</span>
                      <span class="tag">
                        <a
                          :class="{
                            jy: row.created_name.indexOf(user.username) === -1,
                            'return-btn': true
                          }"
                          @click="sourceRejected(row)"
                          >还原</a
                        >
                      </span>
                    </li>
                  </ul>
                </div>
                <!-- 分页 -->
                <div class="page-bottom mar-t-30">
                  <!-- <el-pagination
                    :hide-on-single-page="singPage"
                    @size-change="handleSizeChange"
                    @current-change="handleCurrentChange"
                    :current-page="currentPage"
                    :page-size="size"
                    :page-sizes="[10, 20, 50]"
                    layout="sizes, prev, pager, next"
                    :total="total"
                  >
                  </el-pagination> -->
                  <el-pagination
                    :hide-on-single-page="singPage"
                    @size-change="handleSizeChange"
                    @current-change="handleCurrentChange"
                    :current-page="currentPage"
                    layout="prev, pager, next"
                    :total="total"
                  >
                  </el-pagination>
                </div>
                <!-- @end分页 -->
              </div>
            </el-tab-pane>
          </el-tabs>
        </div>
      </el-card>
    </div>
    <article-detail ref="detail" />
  </el-container>
</template>
<script>
import { markList, cancelDocMark } from '@/api/message';
import articleDetail from '@components/common/article-detail.vue';
import { shieldSourceList, dropShieldSource } from '@/api/source';
import { mapGetters } from 'vuex';
export default {
  data() {
    return {
      data: [],
      sourceData: [],
      size: 10,
      total: 0,
      currentPage: 1,
      loading: false,
      activeName: 'info',
      singPage: true
    };
  },
  watch: {
    activeName: function(name) {
      if (name === 'info') {
        this.currentPage = 1;
        this.getData();
      } else {
        this.getSourceData();
      }
    }
  },
  mounted() {
    if (this.$route.params.hasOwnProperty('shield')) {
      this.activeName = 'source';
      this.currentPage = 1;
      this.total = 0;
      this.getSourceData();
    } else {
      this.currentPage = 1;
      this.total = 0;
      this.getData();
    }
  },
  computed: {
    ...mapGetters(['user'])
  },
  components: {
    articleDetail
  },
  methods: {
    handleSizeChange(size) {
      this.size = size;
      this.currentPage = 1;

      if (this.activeName === 'source') {
        this.getSourceData();
      } else {
        this.getData();
      }
    },
    handleCurrentChange(val) {
      this.currentPage = val;

      if (this.activeName === 'source') {
        this.getSourceData();
      } else {
        this.getData();
      }
    },
    sourceRejected(row) {
      if (row.created_name.indexOf(this.user.username) === -1) {
        return false;
      }

      dropShieldSource({ uuid: row.uuid })
        .then(res => {
          if (res.data.state) {
            this.$message.success('还原成功。');
            if (this.sourceData.length <= 1 && this.currentPage > 1) {
              this.currentPage = this.currentPage - 1;
            }
            this.getSourceData();
          } else {
            this.$message.error(res.data.error);
          }
        })
        .catch(e => {
          window.console.error(e);
        });
    },
    getSourceData() {
      this.loading = true;
      shieldSourceList({ page: this.currentPage, size: this.size })
        .then(res => {
          if (res.data.state) {
            const { data, total } = res.data.data;
            this.sourceData = data;
            this.total = total;
          } else {
            this.$message.error(res.data.error);
          }
          this.loading = false;
        })
        .catch(e => {
          this.loading = false;
          window.console.error(e);
        });
    },
    getData() {
      this.loading = true;
      markList({ page: this.currentPage, size: this.size })
        .then(res => {
          if (res.data.state) {
            this.loading = false;
            const { data, total } = res.data.data;
            this.data = data;
            this.total = total >= 5000 ? 5000 : total;
          } else {
            this.$message.error(res.data.error);
          }
        })
        .catch(e => {
          window.console.error(e);
        });
    },
    cancelMark(item, index) {
      if (item.uid.indexOf(this.user.id) === -1) {
        return false;
      }
      this.$message.success('还原该标记信息成功');
      if (this.data.length <= 1 && this.currentPage > 1) {
        this.currentPage = this.currentPage - 1;
        this.getData();
      }
      this.data.splice(index, 1);
      cancelDocMark({ rowkey: item.rowkey })
        .then(res => {
          if (res.data.state) {
            // this.$message.success('还原该标记信息成功')
            // this.data.splice(index, 1)
            // if (this.data.length < 1) {
            //   this.getData()
            // }
          } else {
            // this.$message.error(res.data.error)
          }
        })
        .catch(e => {
          window.console.error(e);
        });
    },
    showDetail(item) {
      this.$refs.detail.showDetail(item.rowkey, 'shield');
    },
    handleClick(tab, event) {
      console.log(tab, event);
    }
  }
};
</script>
<style scoped>
.shield-cont >>> .el-card__body {
  padding: 20px 0 40px;
}
.shield-cont .sub {
  color: #7c7c7c;
  font-weight: normal;
  float: left;
}
.shield-cont li {
  line-height: 60px;
  height: 60px;
  font-size: 12px;
}
.shield-cont li:not(:last-child) {
  border-bottom: 1px solid rgba(10, 10, 10, 0.1);
}
.shield-cont .shield-info .mod-list-title2 {
  width: 41%;
}
.shield-cont .shield-info .mod-list-title1 {
  width: 18%;
  text-align: center;
}
.shield-cont .shield-info .tag {
  width: 15%;
}
.shield-cont .shield-info .tag:last-child {
  width: 6%;
}
.shield-cont >>> .return-btn {
  width: 60px;
  height: 25px;
  line-height: 25px;
  padding: 0;
  text-align: center;
  border: 1px #7c7c7c solid;
  color: #7c7c7c;
  border-radius: 18px;
  display: block;
  margin: 18px auto 0;
  font-size: 12px;
}
.shield-cont >>> .return-btn.jy {
  border-color: #d2d2d2;
  color: #d2d2d2;
}
/* 屏蔽信源 */
.shield-source .mod-list-title1 {
  width: 12%;
  text-align: left;
}
.shield-source .mod-list-title2 {
  width: 16%;
}
.shield-source .tag {
  width: 13%;
}
</style>
